import { Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { cameraSettingsService } from '../../services/camera-settings.service';
import { Inject } from '@angular/core';
import { camera } from '../../../../@shared/models/camera';
import { dialogApiControl } from '../../models/dialogApiControl';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'hrt-dialog-camera',
  templateUrl: './dialog-camera.component.html',
  styleUrl: './dialog-camera.component.scss'
})
export class DialogCameraComponent {

  lockCode: boolean = false;
  apicontrol: dialogApiControl = dialogApiControl.Create;
  idCam: string = null;

  optionsGmaps: google.maps.MapOptions = {
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    keyboardShortcuts: false,
    zoom: 5,
  };

  markerOptions: google.maps.MarkerOptions = { draggable: false };
  markerPosition: google.maps.LatLngLiteral = null;
  centerPosition: google.maps.LatLngLiteral = {lng: -2.86212124, lat: 40.44428073};

  constructor(private dialogRef: MatDialogRef<DialogCameraComponent>,
    private formBuilder: FormBuilder,
    private cameraSettingsService: cameraSettingsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
  ) { }


  ngOnInit() {
    if (this.data.isModifying)
      this.apicontrol = dialogApiControl.Modify;

    if (this.data.code) {
      this.setupData(this.data.code);
      this.lockCode = true;
    }
  }

  cameraForm = this.formBuilder.group({
    code: ['', Validators.required],
    desc: ['', Validators.required],
    watchzone: ['', Validators.required],
    long: ['', [Validators.required, Validators.pattern(/^-?[0-9]\d*(\.\d+)?$/)]],
    lat: ['', [Validators.required, Validators.pattern(/^-?[0-9]\d*(\.\d+)?$/)]],
    warning: ['', [Validators.required, Validators.pattern(/^-?[0-9]\d*(\.\d+)?$/)]],
    critical: ['', [Validators.required, Validators.pattern(/^-?[0-9]\d*(\.\d+)?$/)]],
  });

  setupData(camera: camera) {
    this.cameraForm.controls['code'].setValue(camera.code);
    if (camera.id) {
      this.cameraForm.controls['desc'].setValue(camera.description);
      this.cameraForm.controls['watchzone'].setValue(camera.watch_zone);
      this.cameraForm.controls['long'].setValue(camera.location.longitude.toString());
      this.cameraForm.controls['lat'].setValue(camera.location.latitude.toString());
      this.cameraForm.controls['warning'].setValue(camera.thresholds.warning_level.toString());
      this.cameraForm.controls['critical'].setValue(camera.thresholds.critical_level.toString());
      this.idCam = camera.id;
    }
  }

  addMarker(event: google.maps.MapMouseEvent) {
    this.markerPosition = (event.latLng.toJSON());
    this.cameraForm.controls['long'].setValue(event.latLng.toJSON().lng.toFixed(8).toString());
    this.cameraForm.controls['lat'].setValue(event.latLng.toJSON().lat.toFixed(8).toString());
  }

  searchLocation() {
    if (Number(this.cameraForm.value.long) && Number(this.cameraForm.value.lat)) {
      this.markerPosition = {lng: Number(this.cameraForm.value.long), lat: Number(this.cameraForm.value.lat)};

    }
    
  }

  createBodyCamera() {
    return (
      {
        "code": this.cameraForm.value.code,
        "description": this.cameraForm.value.desc,
        "watch_zone": this.cameraForm.value.watchzone,
        "location": {
          "latitude": this.cameraForm.value.lat,
          "longitude": this.cameraForm.value.long
        },
        "thresholds": {
          "warning_level": this.cameraForm.value.warning,
          "critical_level": this.cameraForm.value.critical
        }
      }
    )
  }

  createNewCamera() {
    this.cameraSettingsService
      .createNewCamera(this.createBodyCamera())
      .subscribe({
        next: (data: any) => {
          return;
        },
        error: () => {
          console.error("something went wrong.");
        },
      });
  }

  updateCamera() {
    this.cameraSettingsService
      .updateCamera(this.createBodyCamera(), this.idCam)
      .subscribe({
        next: (data: any) => {
          return;
        },
        error: () => {
          console.error("something went wrong.");
        },
      });
  }

  isValid(): boolean {
    return !(this.cameraForm.status == 'VALID');
  }

  apply() {
    if (this.cameraForm.status == 'VALID') {
      switch (this.apicontrol) {
        case dialogApiControl.Create:
          this.createNewCamera();
          break;
        case dialogApiControl.Modify:
          this.updateCamera();
          break;
      }
      this.dialogRef.close(this.cameraForm);
    }
  }


  closeDialog() {
    this.dialogRef.close();
  }

}
