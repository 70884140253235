<svg width="902" height="314" viewBox="0 0 902 314" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="hertaverse_logo">
    <g id="herta">
    <path id="Vector" d="M257.83 113.57H273.73V150.72H273.98C276.05 147.64 278.84 145.12 282.35 143.26C285.55 141.47 289.54 140.41 293.52 140.41C303.99 140.41 316.63 146.82 316.63 167.49V202.06H300.73V169.57C300.73 160.12 296.98 153.25 287.62 153.25C280.83 153.25 276.23 157.81 274.44 162.15C273.84 163.51 273.73 165.03 273.73 166.77V202.06H257.83V113.57Z" fill="#02BEBE" class="svg-elem-1"></path>
    <path id="Vector_2" d="M344.99 176.41C345.48 186.47 354.65 191.6 365.3 191.6C373.17 191.6 378.6 190.47 383.84 188.6L386.22 199.4C380.6 201.73 373.01 203.55 363.2 203.55C342.32 203.55 329.84 191.21 329.84 172.83C329.84 157.33 340.09 140.41 361.61 140.41C383.47 140.41 389.83 158.04 389.83 170.24C389.83 172.87 389.55 175.21 389.23 176.41H344.99ZM374.82 165.4C374.87 161.19 372.7 150.98 360.67 150.98C350.09 150.98 345.76 159.54 344.95 165.4H374.82Z" fill="#02BEBE" class="svg-elem-2"></path>
    <path id="Vector_3" d="M402.93 162.03C402.93 153.14 402.81 147.33 402.44 141.89H416.16L416.69 154.11H417.13C420.31 144.84 428 140.41 435.02 140.41C436.57 140.41 437.49 140.46 438.85 140.78V155.49C437.39 155.17 435.9 154.96 433.84 154.96C425.7 154.96 420.52 159.84 419.18 166.86C418.95 168.17 418.84 169.64 418.84 171.05V202.06H402.94V162.03H402.93Z" fill="#02BEBE" class="svg-elem-3"></path>
    <path id="Vector_4" d="M471.19 124.29V141.89H486.6V153.81H471.19V178.81C471.19 186.17 472.85 190.54 479.51 190.54C482.46 190.54 484.65 190.19 486.03 189.78L486.35 201.79C483.97 202.73 479.61 203.54 474.46 203.54C468.43 203.54 463.47 201.35 460.58 198.18C457.19 194.56 455.71 188.63 455.71 181.1V153.81H446.66V141.89H455.71V127.97L471.19 124.29Z" fill="#02BEBE" class="svg-elem-4"></path>
    <path id="Vector_5" d="M535.941 202.06L534.811 195.16H534.421C530.561 199.94 523.651 203.54 515.021 203.54C501.621 203.54 494.771 194.32 494.771 185.61C494.771 170.52 508.451 162.45 533.461 162.61V161.81C533.461 158.63 532.191 151.33 520.171 151.38C513.751 151.38 506.721 153.5 502.341 156.25L499.171 146.09C504.241 143.03 512.521 140.4 522.711 140.4C543.281 140.4 549.301 153.09 549.301 166.42V187.29C549.301 193.02 549.62 198.42 550.29 202.05H535.941V202.06ZM533.891 172.81C521.291 172.6 510.701 175.14 510.701 183.86C510.701 189.45 514.651 192.33 520.091 192.33C527.081 192.33 531.861 187.85 533.431 183.52C533.781 182.42 533.891 181.19 533.891 180.13V172.81Z" fill="#02BEBE" class="svg-elem-5"></path>
    </g>
    <g id="verse">
    <path id="Vector_6" d="M575.32 141.89L586.341 172.64C588.341 178.12 589.741 182.78 590.961 187.41H591.35C592.62 182.64 594.11 178.03 595.99 172.64L606.75 141.89H623.73L598.42 202.06H582.43L558.021 141.89H575.32Z" fill="#662482" class="svg-elem-6"></path>
    <path id="Vector_7" d="M643.11 176.41C643.6 186.47 652.77 191.6 663.42 191.6C671.29 191.6 676.72 190.47 681.96 188.6L684.34 199.4C678.72 201.73 671.13 203.55 661.32 203.55C640.44 203.55 627.96 191.21 627.96 172.83C627.96 157.33 638.21 140.41 659.73 140.41C681.59 140.41 687.95 158.04 687.95 170.24C687.95 172.87 687.67 175.21 687.35 176.41H643.11ZM672.94 165.4C672.99 161.19 670.82 150.98 658.79 150.98C648.21 150.98 643.88 159.54 643.07 165.4H672.94Z" fill="#662482" class="svg-elem-7"></path>
    <path id="Vector_8" d="M701.051 162.03C701.051 153.14 700.931 147.33 700.561 141.89H714.281L714.811 154.11H715.251C718.431 144.84 726.121 140.41 733.141 140.41C734.691 140.41 735.611 140.46 736.971 140.78V155.49C735.511 155.17 734.021 154.96 731.961 154.96C723.821 154.96 718.641 159.84 717.301 166.86C717.071 168.17 716.961 169.64 716.961 171.05V202.06H701.061V162.03H701.051Z" fill="#662482" class="svg-elem-8"></path>
    <path id="Vector_9" d="M747.87 187.56C751.6 189.85 758.95 192.36 764.88 192.36C772.24 192.36 775.26 189.45 775.26 185.65C775.26 181.47 772.53 179.54 764.53 177.21C751.81 173.61 746.35 166.99 746.42 159.53C746.42 148.87 755.63 140.42 770.73 140.42C777.95 140.42 784.42 142.17 788.16 144.23L785.04 155.22C782.43 153.79 776.84 151.46 770.81 151.46C765.11 151.46 761.84 154.01 761.84 157.87C761.84 161.87 765.22 163.69 773.43 166.17C785.36 169.74 790.68 175.21 790.75 184.53C790.75 195.09 782.21 203.56 764.8 203.56C756.67 203.56 749.66 201.69 744.65 199.09L747.87 187.56Z" fill="#662482" class="svg-elem-9"></path>
    <path id="Vector_10" d="M815.41 176.41C815.9 186.47 825.07 191.6 835.72 191.6C843.59 191.6 849.02 190.47 854.26 188.6L856.64 199.4C851.02 201.73 843.43 203.55 833.62 203.55C812.74 203.55 800.26 191.21 800.26 172.83C800.26 157.33 810.51 140.41 832.03 140.41C853.89 140.41 860.25 158.04 860.25 170.24C860.25 172.87 859.97 175.21 859.65 176.41H815.41ZM845.23 165.4C845.28 161.19 843.11 150.98 831.08 150.98C820.5 150.98 816.17 159.54 815.36 165.4H845.23Z" fill="#662482" class="svg-elem-10"></path>
    </g>
    <g id="green">
    <path id="Vector_11" d="M108.551 239L136.101 265.89L163.661 239L136.101 191.27L108.551 239Z" fill="#02BEBE" class="svg-elem-11"></path>
    <path id="Vector_12" d="M193.53 91.7998L230.6 102.22L221.09 139.53H165.98L193.53 91.7998Z" fill="#02BEBE" class="svg-elem-12"></path>
    <path id="Vector_13" d="M51.1104 139.53L41.6104 102.22L78.6703 91.7998L106.23 139.53H51.1104Z" fill="#02BEBE" class="svg-elem-13"></path>
    </g>
    <g id="lila">
    <path id="Vector_14" d="M163.661 74.5502L136.101 47.6602L108.551 74.5502L136.101 122.28L163.661 74.5502Z" fill="#662482" class="svg-elem-14"></path>
    <path id="Vector_15" d="M106.23 174.02H51.1104L41.6104 211.33L78.6703 221.75L106.23 174.02Z" fill="#662482" class="svg-elem-15"></path>
    <path id="Vector_16" d="M165.98 174.02L193.53 221.75L230.6 211.33L221.09 174.02H165.98Z" fill="#662482" class="svg-elem-16"></path>
    </g>
    </g>
    </svg>
    