<div class="container">
  <mat-card class="card-naked login-card">
    <mat-card-header class="logo-login" (click)="openHertaverse()">
      <ngx-countify-brand class="logo-img"></ngx-countify-brand>  
    </mat-card-header>

    <mat-card-content class="card-login-box">
      <form [formGroup]="form" (ngSubmit)="login()">
       
        <mat-form-field class="login-form-layout" appearance="outline">
          <mat-label>User</mat-label>
          <input matInput name="email/user" formControlName="email" />
          <mat-icon class="icon-gray" matSuffix>account_circle</mat-icon>
          <mat-error class="error-message-in">Invalid User</mat-error>
        </mat-form-field>

        <mat-form-field class="login-form-layout" appearance="outline">
          <mat-label>Password</mat-label>
          <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" />
          <button
            type="button"
            class="icon-gray"
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide">
            <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
          <mat-error class="error-message-in">Invalid Password</mat-error>
        </mat-form-field>

        <div class="remember-forgot-container">
          <p><a class="text-link-login" href="https://mailchi.mp/ae1daedf6d3b/countify" target="_blank">Don't have an account?</a></p>
        </div>
        

        <div class="error-message-out" *ngIf="!loginValid">
          <mat-error >Invalid login credentials</mat-error>
        </div>

        <button class="login-submit-button" mat-raised-button type="submit">Login</button>
      </form>

    </mat-card-content>
  </mat-card>
</div>

<!-- Footer with Expansion Panel -->
<div class="footer">
  <mat-expansion-panel class="footer-expansion-panel" [expanded]="panelOpenState" (opened)="togglePanelState()" (closed)="togglePanelState()">
    <mat-expansion-panel-header>
      
      <mat-panel-title>
      </mat-panel-title>

      <mat-panel-description>
        <div class="main-text-login-expansion-panel"> 
        <p>Hertaverse S.L.© | Pallars 108 | 08018 Barcelona (Spain) | +34 936 020 888 |
       <a class="text-link-login" href="https://www.hertaverse.ai" target="_blank">www.hertaverse.ai</a></p> 
     </div>
      </mat-panel-description>

    </mat-expansion-panel-header>

    <div class="footer-content">
      <p>Power your understanding of the world with our AI-powered, cloud-hosted image analysis solutions.</p>
      <button color="accent" class="apply-buttons" mat-raised-button (click)="openHertaverse()">Demo!</button>
    </div>
  </mat-expansion-panel>
</div>
