import { Component, ViewChild, HostListener } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { cameraSettingsService } from '../../services/camera-settings.service';
import { camera } from '../../../../@shared/models/camera';
import { MatDialog } from '@angular/material/dialog';
import { DialogCameraComponent } from '../dialog-camera/dialog-camera.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cameraSettings',
  templateUrl: './camera-settings.component.html',
  styleUrls: ['./camera-settings.component.scss'],
})
export class CameraSettingsComponent {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource: MatTableDataSource<camera>;

  // Columnas que se mostrarán en la tabla
  displayedColumns: string[] = ['code', 'desc', 'watchzone', 'location', 'threshold', 'isComplete', 'active', 'options'];

  // Tamaños de página para el paginador
  pageSize = 7 ; // Valor inicial
  pageSizeOptions = [7, 10, 14, 30, 50]; // Opciones de tamaño de página
  lenPaginator = 0; // Longitud del paginador
  searchRequestSubscriptions: Subscription[] = []; // Suscripciones para solicitudes de búsqueda

  private element_data: camera[] = []; // Datos de la cámara

  constructor(private cameraSettingsService: cameraSettingsService, public dialog: MatDialog) {
    this.dataSource = new MatTableDataSource<camera>(this.element_data);
    this.getEveryCameras();
    this.adjustPageSize(window.innerHeight); // Ajustar tamaño inicial según la ventana
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  // Listener para el tamaño de la ventana
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.adjustPageSize(window.innerWidth); // Ajusta el tamaño de la página según la nueva altura
  }


  // Ajustar el tamaño de la página según el ancho de la ventana
  adjustPageSize(viewportHeight: number) {
    if (viewportHeight > 1440) {
      this.pageSize = 20;  // Pantallas grandes
    } else if (viewportHeight > 1080) {
      this.pageSize = 14;  // Pantallas medianas
    } else if (viewportHeight > 700) {
      this.pageSize = 10;  // Pantallas pequeñas
    } else if (viewportHeight > 500) {
      this.pageSize = 7;  // Pantallas muy pequeñas
    } else {
      this.pageSize = 3;  // Para pantallas de móviles
    }

    if (this.paginator) {
      this.paginator.pageSize = this.pageSize;
      this.paginator.pageSizeOptions = this.pageSizeOptions;
    }
  }

  // Obtener todas las cámaras
  getEveryCameras() {
    this.element_data = [];

    this.cameraSettingsService.getCameras().subscribe({
      next: (data: camera[]) => {
        const temp = Object.keys(data).map((key) => data[key]);
        for (const elem of temp) {
          elem.isCompleted = true;
          this.element_data.push(elem);
        }
        this.dataSource.data = this.element_data;
      },
      error: () => {
        console.error('something went wrong.');
      },
    });

    this.cameraSettingsService.getCamerasPending().subscribe({
      next: (data: any[]) => {
        const temp = Object.keys(data).map((key) => data[key]);
        for (let elem of temp) {
          this.element_data.push(this.createPendingCam(elem));
        }
        this.dataSource.data = this.element_data;
      },
      error: () => {
        console.error('something went wrong.');
      },
    });
  }

  // Crear una cámara pendiente
  createPendingCam(elem: string): camera {
    const cam: camera = new camera();
    cam.code = elem;
    cam.isCompleted = false;
    cam.is_active = false;
    return cam;
  }

  // Determinar si una cámara está completada
  isCompleted(element: any) {
    return { 'background-color': element.isCompleted ? 'green' : 'red' };
  }

  // Alternar el estado activo de la cámara
  slideToggled(cam: camera) {
    const camFound = this.element_data.find((elem) => cam.id === elem.id);
    camFound.is_active = !camFound.is_active;

    this.updateActive(camFound);
  }

  // Actualizar el estado activo de la cámara
  updateActive(cam: camera) {
    this.cameraSettingsService
      .updateCamera(this.cameraSettingsService.createBodyFromCamera(cam), cam.id)
      .subscribe({
        next: () => {
          return;
        },
        error: () => {
          console.error('something went wrong.');
        },
      });
  }

  // Método para abrir el diálogo
  openDialog(code = null, isModifying = false): void {
    const dialogRef = this.dialog.open(DialogCameraComponent, {
      data: { code, isModifying },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getEveryCameras(); // Refrescar la tabla después de cerrar el diálogo
    });
  }

  // Manejar el cambio de texto en el campo de búsqueda
  onTextChange(changedText: string) {
    this.cancelPendingRequests();

    this.element_data = [];
    const searchSub = this.cameraSettingsService.getCameras(changedText).subscribe({
      next: (data: camera[]) => {
        const temp = Object.keys(data).map((key) => data[key]);
        for (const elem of temp) {
          elem.isCompleted = true;
          this.element_data.push(elem);
        }
        this.dataSource.data = this.element_data;
      },
      error: () => {
        console.error('something went wrong.');
      },
    });

    const searchSub2 = this.cameraSettingsService.getCamerasPending(changedText).subscribe({
      next: (data: any[]) => {
        const temp = Object.keys(data).map((key) => data[key]);
        for (const elem of temp) {
          this.element_data.push(this.createPendingCam(elem));
        }
        this.dataSource.data = this.element_data;
      },
      error: () => {
        console.error('something went wrong.');
      },
    });

    this.searchRequestSubscriptions.push(searchSub);
    this.searchRequestSubscriptions.push(searchSub2);
  }

  // Cancelar las solicitudes pendientes
  cancelPendingRequests() {
    this.searchRequestSubscriptions.forEach((sub) => sub.unsubscribe());
  }
}
