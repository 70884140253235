import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

export const enum TOAST_OPTION {
  INFO,
  WARNING,
  ERROR,
  SUCCESS,
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(
    private readonly toasterService: ToastrService,
    private readonly translateService: TranslateService
  ) {}

  public displayToast(
    title: string,
    messageKey: string,
    toastOption: TOAST_OPTION,
    durationInMs?: number
  ) {
    const defaultToastSettings = {
      timeOut: durationInMs || 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    };
    this.displayCustomizedToast(
      this.translateService.instant(title),
      this.translateService.instant(messageKey),
      toastOption,
      defaultToastSettings
    );
  }

  private displayCustomizedToast(
    title: string,
    message: string,
    toastOption: TOAST_OPTION,
    toastConfig
  ) {
    switch (toastOption) {
      case TOAST_OPTION.ERROR:
        this.toasterService.error(message, title, toastConfig);
        break;
      case TOAST_OPTION.INFO:
        this.toasterService.info(message, title, toastConfig);
        break;
      case TOAST_OPTION.WARNING:
        this.toasterService.warning(message, title, toastConfig);
        break;
      case TOAST_OPTION.SUCCESS:
        this.toasterService.success(message, title, toastConfig);
        break;
      default:
        this.toasterService.show(message, title, toastConfig);
    }
  }
}
