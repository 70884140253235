import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { AuthModule } from './auth/auth.module';
import { AuthService } from './auth/services/auth.service';
import { JwtModule } from '@auth0/angular-jwt';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors } from '@angular/common/http';
import { ErrorInterceptor } from './auth/interceptors/error.interceptor';
import { Token } from './auth/models/token';
import { HttpService } from './services/http.service';
import { ToastService } from '../@shared/services/toast.service';
import { loadingInterceptor } from './auth/interceptors/loading.interceptor';
import { authInterceptor } from './auth/interceptors/auth.interceptor';

export const NB_CORE_PROVIDERS = [AuthService, HttpService, ToastService];

@NgModule({
  imports: [
    CommonModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          const token = JSON.parse(localStorage.getItem('user_token') ?? '{}') as Token;
          return token.access_token ?? '';
        },
        allowedDomains: [
          /(api-develop.countify.cloud)./,
          /(api.countify.cloud)./,
          /(localhost)./,
          /(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})./,
        ],
      },
    }),
  ],
  exports: [AuthModule],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
        provideHttpClient(withInterceptors([loadingInterceptor, authInterceptor])),
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
      ],
    };
  }
}
