<div class="container" *ngIf="!hideComponent" [ngStyle]="{'background-color': warningType, 'border': '2px solid ' + borderColor}">
    <div class="icon-container">
        <mat-icon [ngStyle]="{'color': borderColor}" >{{icon}}</mat-icon>
    </div>
    <div [ngStyle]="{'color': fontColor}" class="message-container">
        <div class="text">
            {{"warningMessageDashboard1" | translate}} {{message}} {{"warningMessageDashboard2" | translate}}
        </div>
        <div *ngIf="url" class="hyperlink" (click)="moveToUrl()">{{"clickHere" | translate}}</div>
    </div>
    <div class="close-container">
        <button mat-button *ngIf="closable" (click)="closeButtonClicked()">
            <mat-label>
                <mat-icon [ngStyle]="{'color': fontColor}" >close</mat-icon>
            </mat-label>
        </button>
    </div>
</div>