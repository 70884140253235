import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatCardModule} from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { MapCardComponent } from './pages/map-card/map-card.component';
import { GoogleMap , MapMarker, MapCircle } from '@angular/google-maps';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { SharedModule } from '../../@shared/shared.module';
import { AlertComponent } from './pages/alert-dashboard/alert-dashboard.component';

@NgModule({
  declarations: [DashboardComponent, MapCardComponent],
  imports: [SharedModule, CommonModule, DashboardRoutingModule,
            MatCardModule, MatIconModule, GoogleMap, MapMarker,
            MapCircle, AlertComponent],
})
export class DashboardModule { }
