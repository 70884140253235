import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LiveRoutingModule } from './camera-settings-routing.module';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDialogModule} from '@angular/material/dialog';
import { CameraSettingsComponent } from './pages/cameraSettings/camera-settings.component';
import { DialogCameraComponent } from './pages/dialog-camera/dialog-camera.component';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import {MatMenuModule} from '@angular/material/menu';
import { SharedModule } from '../../@shared/shared.module';
import { GoogleMap , MapMarker, MapCircle } from '@angular/google-maps';
import {MatDividerModule} from '@angular/material/divider';
import { SearchInputComponent } from '../../@shared/components/search-input/search-input.component';




@NgModule({
  declarations: [CameraSettingsComponent, DialogCameraComponent],
  imports: [CommonModule, LiveRoutingModule, MatCardModule,
    MatDialogModule, MatInputModule, MatSelectModule, ReactiveFormsModule, 
    MatGridListModule, MatTableModule, MatPaginatorModule, MatIconModule,
    MatButtonModule, MatSlideToggleModule, MatFormFieldModule,
    MatMenuModule, SharedModule, GoogleMap , MapMarker, MapCircle,
    MatDividerModule, SearchInputComponent
  ],
  exports: [CameraSettingsComponent, DialogCameraComponent]
    
})
export class CameraSettingsModule {}
