<h2 mat-dialog-title>{{ title }}</h2>
<mat-dialog-content>
  <ng-content></ng-content>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    (click)="onCancel()"
    mat-button>
    {{ 'no' | translate }}
  </button>
  <button
    class="submit-save"
    color="primary"
    mat-button
    (click)="onConfirm()"
    mat-flat-button>
    {{ 'yes' | translate }}
  </button>
</mat-dialog-actions>
