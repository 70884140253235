import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { FormBuilder, Validators } from '@angular/forms';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginValid = true;
  formError = '';
  panelOpenState = false;  // Estado del panel de expansión, inicializado en false
  hide = true;

  form = this.fb.group({
    email: ['', Validators.required],
    password: ['', Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    // Inicializar el estado del panel a cerrado
    this.panelOpenState = false; // Asegúrate de que el panel esté cerrado al inicio
  }

  login() {
    this.authService
      .login(this.form.controls.email.value ?? '', this.form.controls.password.value ?? '')
      .subscribe({
        next: () => {
          this.loginValid = true;
          this.router.navigateByUrl('/');
        },
        error: () => {
          this.loginValid = false;
        },
      });
  }

  // Método para manejar la expansión y contracción del panel
  togglePanelState() {
    this.panelOpenState = !this.panelOpenState;  // Alterna el estado del panel

    // Agrega o quita la clase del contenedor para manejar el movimiento
    const container = document.querySelector('.container');
    if (this.panelOpenState) {
      container?.classList.add('footer-expanded');  // Agrega clase para mover el formulario hacia arriba
    } else {
      container?.classList.remove('footer-expanded'); // Remueve clase para mover el formulario hacia abajo
    }
  }

  openHertaverse() {
    window.open('https://www.hertaverse.ai', '_blank');
  }
}

