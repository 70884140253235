import { Component } from '@angular/core';
import { environment } from '../@shared/common-environment/common-environment';
import { AuthService } from '../@core/auth/services/auth.service';

@Component({
  selector: 'hrt-modules',
  templateUrl: './modules.component.html',
})
export class ModulesComponent {
  protected readonly environment = environment;

  constructor(protected authService: AuthService) {}
}
