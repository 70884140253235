import { CanMatchFn, Route } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';
import { RoutesByRole } from '../models/routes-by-role';
import { AppRoutes } from '../models/routes.enum';

export const CanMatchRoleGuard: CanMatchFn = (route: Route) => {
  const auth = inject(AuthService);
  if (!auth.user()) return false;
  return RoutesByRole.find((x) => x.role === auth.user().role).routes.includes(
    route.path as AppRoutes
  );
};
