import { HttpService } from '../../../@core/services/http.service';
import { statusData } from '../models/statusData';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { groupData } from '../models/groupData';


@Injectable({
    providedIn: 'root',
  })
export class dashboardService {

    constructor(
      private api: HttpService
    ) {}

    getStatusData(): Observable<statusData> {

    return this.api.get('global/status').pipe(
        map((result) => {
            return { ...result };
            })
        );
    }

    getGroupsData(): Observable<groupData[]> {

    return this.api.get('watchzones/status').pipe(
        map((result) => {
            return { ...result };
            })
        );
    }

    getUnfinishedCameras(): Observable<any> {
        return this.api.get('cameras/setup/pending').pipe(
            map((result) => {
                return { ...result };
                })
            );
        }
}