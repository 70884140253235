import {Component, Input} from '@angular/core';
import { warningType } from '../../../../@shared/models/warningType';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'alert-dashboard',
  standalone: true,
  imports: [MatIconModule, MatButtonModule, MatFormFieldModule, CommonModule, TranslateModule],
  templateUrl: './alert-dashboard.component.html',
  styleUrl: './alert-dashboard.component.scss'
})
export class AlertComponent {
  @Input() warningType: warningType = warningType.Error;
  @Input() message: string = "An error occured.";
  @Input() isClosable: string = 'true';
  @Input() url: string = null;
  @Input() messageVariable: string = null;

  closable: boolean = true;
  hideComponent: boolean = false;
  fontColor: string = 'white';
  public borderColor: string = '#FF9800';
  icon: string = "warning";

  constructor(private router: Router) {}

  ngOnInit() {
    this.closable = this.isClosable === 'true';
    this.fontColor = this.warningType == warningType.Error ? 'white' : 'black';
    switch (this.warningType) {
      case warningType.Error:
        this.fontColor = 'white';
        this.icon = "warning";
        break;
      case warningType.Warning:
        this.fontColor = 'black';
        this.icon = "warning";
        break;
      default:
        this.fontColor = 'black';
        this.icon = "done";
    }
  }

  closeButtonClicked() {
    this.hideComponent = true;
  }

  moveToUrl() {
    this.router.navigateByUrl(this.url);
  }
}
