import { Component } from '@angular/core';
import { LoadingService } from '../../../@core/auth/services/loading.service';

@Component({
  selector: 'hrt-spinner',
  templateUrl: './spinner.component.html',
  styleUrl: './spinner.component.scss',
})
export class SpinnerComponent {
  constructor(public loadingService: LoadingService) {}
}
