import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { catchError, switchMap } from 'rxjs/operators';
import { TOAST_OPTION, ToastService } from '../../../@shared/services/toast.service';
import { throwError } from 'rxjs';
import { Token } from '../models/token';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const auth = inject(AuthService);
  const toast = inject(ToastService);

  const token = JSON.parse(localStorage.getItem('user_token') ?? '{}') as Token;
  req = req.clone({
    setHeaders: {
      Authorization: `Bearer ${token.access_token}`}
    });

  if (req.url.includes('auth/refresh')) {
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token.refresh_token}`}
      });
  }
    
  if (
    auth.isTokenExpired() &&
    !req.url.includes('refresh') &&
    !req.url.includes('login') &&
    auth.accessToken()
  ) {
    return auth.refresh().pipe(
      switchMap((newToken) => {
        if (newToken) {
          req = req.clone({
            setHeaders: {
              Authorization: `Bearer ${newToken.access_token}`,
            },
          });
        }
        return next(req);
      }),
      catchError((error) => {
        toast.displayToast('Token', 'CANNOT_REFRESH_AUTH', TOAST_OPTION.ERROR);
        return throwError(() => error);
      })
    );
  } else {
    return next(req);
  }
};
