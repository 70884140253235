import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanMatchRoleGuard } from '../@core/auth/guards/canMatchRole.guard';
import { AppRoutes } from '../@core/auth/models/routes.enum';
import { ModulesComponent } from './modules.component';
import { canActivateAuthGuard } from '../@core/auth/guards/canActivateAuth.guard';

const routes: Routes = [
  {
    path: '',
    component: ModulesComponent,
    canActivate: [canActivateAuthGuard],
    children: [
      {
        path: AppRoutes.CameraSettings,
        canActivate: [canActivateAuthGuard],
        loadChildren: () => import('./cameraSettings/camera-settings.module').then((m) => m.CameraSettingsModule),
        canMatch: [CanMatchRoleGuard],
      },
      {
        path: AppRoutes.Cameras,
        canActivate: [canActivateAuthGuard],
        loadChildren: () => import('./cameras/cameras.module').then((m) => m.CamerasModule),
        canMatch: [CanMatchRoleGuard],
      },
      {
        path: AppRoutes.Dashboard,
        canActivate: [canActivateAuthGuard],
        loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
        canMatch: [CanMatchRoleGuard],
      },
      {
        path: AppRoutes.Heatmap,
        canActivate: [canActivateAuthGuard],
        loadChildren: () => import('./heatmap/heatmap.module').then((m) => m.HeatmapModule),
        canMatch: [CanMatchRoleGuard],
      },
      {
        path: AppRoutes.Statistics,
        canActivate: [canActivateAuthGuard],
        loadChildren: () => import('./statistics/statistics.module').then((m) => m.StatisticsModule),
        canMatch: [CanMatchRoleGuard],
      },
      {
        path: AppRoutes.UserSettings,
        canActivate: [canActivateAuthGuard],
        loadChildren: () => import('./user-settings/user-settings.module').then((m) => m.UserSettingsModule),
        canMatch: [CanMatchRoleGuard],
      },
      {
        path: AppRoutes.ApiSettings,
        canActivate: [canActivateAuthGuard],
        loadChildren: () => import('./api-settings/api-settings.module').then((m) => m.ApiSettingsModule),
        canMatch: [CanMatchRoleGuard],
      },
      {
        path: AppRoutes.AdminSettings,
        canActivate: [canActivateAuthGuard],
        loadChildren: () => import('./admin-settings/admin-settings.module').then((m) => m.AdminSettingsModule),
        canMatch: [CanMatchRoleGuard],
      },
      {
        path: AppRoutes.Settings,
        canActivate: [canActivateAuthGuard],
        loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
        canMatch: [CanMatchRoleGuard],
      },
      {
        path: AppRoutes.ChangePwd,
        canActivate: [canActivateAuthGuard],
        loadChildren: () => import('./changePassword/ChangePassword.module').then((m) => m.ChangePasswordModule),
        canMatch: [CanMatchRoleGuard],
      },
      {
        path: AppRoutes.Thresholds,
        canActivate: [canActivateAuthGuard],
        loadChildren: () => import('./thresholds/thresholds.module').then((m) => m.ThresholdsModule),
        canMatch: [CanMatchRoleGuard],
      },

      { path: '', redirectTo: AppRoutes.Dashboard, pathMatch: 'full' },
      { path: '**', redirectTo: AppRoutes.Dashboard },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ModulesRoutingModule {}
