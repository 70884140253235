import { MatDialogConfig } from '@angular/material/dialog';
import { environment as env } from '../../../environments/environment';
import { VERSION } from '../../../version';

export const environment = {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  appVersion: VERSION,
};

export function dialogConfigFactory<T>(
  custom: { data?: T; width?: string; height?: string } = {}
): MatDialogConfig {
  return {
    disableClose: true,
    autoFocus: true,
    hasBackdrop: true,
    height: 'auto',
    width: 'auto',
    ...custom,
  } as MatDialogConfig;
}

export function getBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const content = reader.result as string;
      resolve(content.split(',').pop());
    };
    reader.onprogress = () => {};
    reader.onerror = (error) => reject(error);
  });
}
