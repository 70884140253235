import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient) {}

  get apiUrl(): string {
    return environment.apiUrl;
  }

  get(endpoint: string, options?): Observable<any> {
    return this.http.get(`${this.apiUrl}/${endpoint}`, options).pipe(
      catchError((error) => {
        if (error.error instanceof ErrorEvent) {
          return throwError('UNK');
        } else {
          return throwError(error);
        }
      })
    );
  }

  post(endpoint: string, data, options?): Observable<any> {
    return this.http.post(`${this.apiUrl}/${endpoint}`, data, options).pipe(
      catchError((error) => {
        if (error.error instanceof ErrorEvent) {
          return throwError('UNK');
        } else {
          return throwError(error);
        }
      })
    );
  }

  put(endpoint: string, data, options?): Observable<any> {
    return this.http.put(`${this.apiUrl}/${endpoint}`, data, options).pipe(
      catchError((error) => {
        if (error.error instanceof ErrorEvent) {
          return throwError('UNK');
        } else {
          return throwError(error);
        }
      })
    );
  }

  patch(endpoint: string, data, options?): Observable<any> {
    return this.http.patch(`${this.apiUrl}/${endpoint}`, data, options).pipe(
      catchError((error) => {
        if (error.error instanceof ErrorEvent) {
          return throwError('UNK');
        } else {
          return throwError(error);
        }
      })
    );
  }

  delete(endpoint: string, options?): Observable<any> {
    return this.http.delete(`${this.apiUrl}/${endpoint}`, options).pipe(
      catchError((error) => {
        if (error.error instanceof ErrorEvent) {
          return throwError('UNK');
        } else {
          return throwError(error);
        }
      })
    );
  }
}
