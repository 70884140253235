<svg width="185" height="50" viewBox="0 0 185 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Frame 1" clip-path="url(#clip0_1785_67)">
    <g id="Group 26086155">
    <g id="fy">
    <path id="Vector" d="M162.659 34.9334L165.444 21.021H163.299L163.548 19.8253H165.694V19.1029C165.911 16.945 166.82 14.9165 168.284 13.3171C169.164 12.4842 170.328 12.0173 171.542 12.011C172.287 11.9542 173.035 12.1183 173.688 12.4842L173.214 13.6799C172.666 13.4212 172.066 13.2981 171.46 13.3171C168.842 13.3171 167.785 15.7935 167.173 19.1313V19.8253H171.044L170.795 21.021H166.842L164.056 34.9334H162.659Z" fill="#275C9A" class="svg-elem-1"></path>
    <path id="Vector_2" d="M172.714 19.8252L174.941 28.8951C175.442 30.9268 175.72 32.1792 175.916 33.236C176.332 32.3748 176.834 31.2896 177.78 29.4251L182.679 19.8252H184.295L178.168 31.5104C176.922 34.1951 175.31 36.6936 173.379 38.9398C172.111 40.3878 170.461 41.4478 168.619 41.9999L168.228 40.7475C169.335 40.3815 170.373 39.8263 171.291 39.107C172.669 37.9682 173.821 36.5801 174.688 35.0185C174.726 34.927 174.748 34.826 174.748 34.7251C174.748 34.6241 174.729 34.5263 174.688 34.4317L170.985 19.8252H172.714Z" fill="#275C9A" class="svg-elem-2"></path>
    </g>
    <g id="counti">
    <path id="Vector_3" d="M86.0881 34.2079C84.5076 34.9619 82.7725 35.3405 81.0216 35.3215C79.9773 35.3878 78.93 35.2364 77.9488 34.8767C76.9677 34.5171 76.0686 33.9555 75.3146 33.2331C74.5606 32.5075 73.9644 31.6336 73.5669 30.6683C73.1694 29.7029 72.977 28.6619 73.0022 27.6176C72.9801 26.5009 73.1915 25.3935 73.6205 24.3619C74.0496 23.3303 74.69 22.4028 75.5008 21.6331C76.3115 20.8633 77.2706 20.2702 78.3211 19.8917C79.3716 19.5131 80.4916 19.3585 81.6052 19.4342C83.2141 19.3774 84.8104 19.7529 86.2269 20.5195L85.6433 21.7719C84.3719 21.0779 82.9428 20.7308 81.4948 20.7718C80.5925 20.6993 79.6871 20.8097 78.829 21.1031C77.9709 21.3933 77.1854 21.8571 76.5134 22.4628C75.8415 23.0685 75.302 23.8067 74.9266 24.6301C74.5512 25.4535 74.3493 26.3431 74.3335 27.2485C74.3177 28.154 74.4849 29.0499 74.8288 29.8859C75.1727 30.7219 75.6837 31.4791 76.3305 32.11C76.9772 32.741 77.7469 33.2331 78.5924 33.558C79.4379 33.8798 80.3401 34.0281 81.2456 33.9871C82.7693 33.9934 84.2741 33.6495 85.6464 32.987L86.0881 34.2079Z" fill="#02BEBE" class="svg-elem-3"></path>
    <path id="Vector_4" d="M90.2931 27.4478C90.2427 28.3059 90.372 29.1608 90.6654 29.9684C90.962 30.7729 91.4194 31.5111 92.0125 32.1326C92.6056 32.7541 93.3186 33.2462 94.1104 33.5806C94.9022 33.915 95.754 34.0822 96.6121 34.0728C97.4954 34.0854 98.3693 33.9182 99.1832 33.5775C99.9971 33.2368 100.732 32.732 101.341 32.0947C101.95 31.4575 102.42 30.7003 102.723 29.8738C103.026 29.0473 103.155 28.1639 103.101 27.2869C103.092 26.4477 102.915 25.618 102.581 24.8451C102.25 24.0722 101.767 23.375 101.164 22.7882C100.562 22.2015 99.8489 21.7409 99.0697 21.4285C98.2904 21.1162 97.4544 20.9616 96.6121 20.9743C95.7698 20.9837 94.9401 21.1604 94.1672 21.4948C93.3943 21.826 92.6939 22.3056 92.1071 22.9081C91.5203 23.5107 91.0566 24.2205 90.7474 25.0029C90.4383 25.7758 90.2837 26.6086 90.2931 27.4478ZM104.663 27.1986C104.701 28.2743 104.518 29.3501 104.124 30.3533C103.729 31.3565 103.13 32.2683 102.37 33.0285C101.606 33.792 100.694 34.3882 99.6911 34.7826C98.6879 35.1769 97.6122 35.3599 96.5332 35.3221C95.5016 35.3536 94.4732 35.1738 93.5141 34.7921C92.5551 34.4103 91.6812 33.8393 90.9525 33.1106C90.2237 32.3818 89.6496 31.5111 89.2678 30.5521C88.8861 29.593 88.7063 28.5677 88.7379 27.5361C88.7 26.4698 88.8798 25.4067 89.2678 24.4129C89.6559 23.4192 90.2427 22.5138 90.9967 21.7566C91.7475 20.9995 92.6466 20.4001 93.6372 20.0026C94.6278 19.6051 95.6909 19.4127 96.7572 19.441C97.7983 19.3906 98.8362 19.5546 99.8079 19.9269C100.78 20.296 101.666 20.8638 102.411 21.5926C103.152 22.3213 103.736 23.1952 104.124 24.1605C104.512 25.1259 104.695 26.1575 104.663 27.1986Z" fill="#02BEBE" class="svg-elem-4"></path>
    <path id="Vector_5" d="M121.73 31.2864C121.73 32.5104 121.73 33.7061 121.73 34.9301H120.282V31.8984C119.705 32.9553 118.85 33.8323 117.809 34.4348C116.768 35.0374 115.581 35.3434 114.376 35.3213C112.651 35.3213 108.669 34.7377 108.669 28.3115V19.964H110.199V28.1159C110.199 31.6492 111.452 34.0973 114.793 34.0973C115.85 34.0973 116.884 33.7976 117.777 33.2297C118.67 32.6619 119.383 31.8543 119.831 30.8984C120.064 30.3337 120.187 29.7279 120.194 29.1191V19.8252H121.752L121.73 31.2864Z" fill="#02BEBE" class="svg-elem-5"></path>
    <path id="Vector_6" d="M127.27 23.4158C127.27 22.1917 127.27 20.9961 127.27 19.772H128.718V22.8037C129.276 21.7501 130.116 20.873 131.141 20.2673C132.169 19.6616 133.343 19.3556 134.535 19.3808C135.927 19.3808 140.438 20.0213 140.438 26.2803V34.877H138.99V26.3371C138.99 23.3874 137.681 20.7721 134.229 20.7721C133.109 20.7847 132.018 21.1317 131.1 21.7721C130.182 22.4126 129.475 23.3148 129.078 24.3622C128.929 24.8007 128.844 25.2613 128.828 25.725V34.9338H127.27V23.4158Z" fill="#02BEBE" class="svg-elem-6"></path>
    <path id="Vector_7" d="M148.098 15.3179V19.826H152.915V21.05H148.126V30.732C148.126 32.5964 148.684 33.9877 150.549 33.9877C151.227 34.0129 151.905 33.9183 152.552 33.7101L152.719 34.9057C151.984 35.1959 151.199 35.3379 150.407 35.3221C149.886 35.3537 149.366 35.2654 148.886 35.0666C148.407 34.8679 147.974 34.565 147.624 34.1801C146.873 33.1201 146.52 31.8298 146.621 30.5364V21.05H143.835V19.826H146.621V15.7911L148.098 15.3179Z" fill="#02BEBE" class="svg-elem-7"></path>
    <path id="Vector_8" d="M156.619 34.82V19.8254H158.149V34.9335L156.619 34.82ZM158.679 15.3457C158.682 15.5224 158.651 15.699 158.584 15.8662C158.518 16.0303 158.42 16.1817 158.297 16.3079C158.174 16.4341 158.023 16.535 157.859 16.6044C157.695 16.6738 157.518 16.7085 157.341 16.7085C157.001 16.6865 156.679 16.5319 156.449 16.2763C156.218 16.0208 156.098 15.6864 156.114 15.3457C156.114 15.0018 156.247 14.6706 156.486 14.4213C156.726 14.1721 157.051 14.0238 157.395 14.0112C157.565 14.0144 157.736 14.0522 157.893 14.1216C158.051 14.1911 158.193 14.2888 158.313 14.415C158.433 14.5412 158.524 14.6863 158.587 14.8441C158.654 15.0018 158.682 15.1753 158.679 15.3457Z" fill="#02BEBE" class="svg-elem-8"></path>
    </g>
    </g>
    <g id="random_pin">
    <path id="Vector_9" d="M55.8835 15.5488L53.9963 9.7084L59.7748 7.66386L64.6676 14.9729L55.8835 15.5488Z" fill="#02BEBE" class="svg-elem-9"></path>
    <path id="Vector_10" d="M35.4243 7.11608L28.5145 12.5611L31.0096 18.1809L36.6813 15.8181L35.4243 7.11608Z" fill="#275C9A" class="svg-elem-10"></path>
    <path id="Vector_11" d="M28.1735 7.02L25.1242 12.3081L19.6868 9.43869L21.6656 0.957445L28.1735 7.02Z" fill="#02BEBE" class="svg-elem-11"></path>
    </g>
    <g id="main_pin">
    <path id="Vector_12" d="M47.4242 35.0452L43.0255 42.6581L47.4242 46.895L51.8229 42.6119L47.4242 35.0452ZM43.0229 16.42L47.4217 12.1138L51.8204 16.42L47.4217 24.033L43.0229 16.42Z" fill="#009CEA" class="svg-elem-12"></path>
    <path id="Vector_13" d="M42.5709 32.2812H33.8403L32.3218 38.2395L38.2415 39.8941L42.5709 32.2812ZM56.5841 19.1621L62.5038 20.8399L60.9854 26.7776H52.1854L56.5841 19.1621Z" fill="#275C9A" class="svg-elem-13"></path>
    <path id="Vector_14" d="M52.1854 32.2813L56.5841 39.8943L62.5038 38.2396L60.9854 32.2813H52.1854ZM33.8403 26.7752L32.3218 20.8374L38.2415 19.1597L42.5735 26.7726H33.8403V26.7752Z" fill="#02BEBE" class="svg-elem-14"></path>
    </g>
    <g id="half_pin">
    <path id="Vector_15" d="M15.1626 34.0552L15.5575 42.8408L21.5606 44.158L22.8767 38.16L15.1626 34.0552Z" fill="#009CEA" class="svg-elem-15"></path>
    <path id="Vector_16" d="M9.55534 34.7476L2.13678 39.5697L4.05692 45.4203L9.95302 43.6285L9.55534 34.7476ZM14.2095 16.2823L20.083 14.4856L22.0307 20.3185L14.6044 25.0679L14.2095 16.2823Z" fill="#275C9A" class="svg-elem-16"></path>
    <path id="Vector_17" d="M17.5836 29.6883L25.4048 33.7245L29.4971 29.1485L24.9849 24.9571L17.5836 29.6883Z" fill="#02BEBE" class="svg-elem-17"></path>
    </g>
    </g>
    <defs>
    <clipPath id="clip0_1785_67">
    <rect width="185" height="50" fill="white" class="svg-elem-18"></rect>
    </clipPath>
    </defs>
    </svg>
    