<div class="padding-page">
    <div class="card-container">
        <mat-card class="card top">
            <mat-card-content>
                <div class="title-text-card icon-secondary">{{'dashboard.countifyNow' | translate}}</div>
                <div class="card-container">
                <div [ngClass]="getColor()" class="header-number-card">{{status.totalPeopleCount ? status.totalPeopleCount : 0}}</div>
                    <mat-icon  [ngClass]="getColor()" >{{getIcon()}}</mat-icon>
                </div>
                <div class="header-text-card icon-textgray">{{'dashboard.visitorsRT' | translate}}</div>
            </mat-card-content>
        </mat-card>
        <mat-card class="card top">
            <mat-card-content>
                <div class="title-text-card icon-secondary">{{'dashboard.countifyCam' | translate}}</div>
                <div class="header-number-card icon-blue">{{status.totalCameraCount ? status.totalCameraCount : 0}}</div>
                <div class="header-text-card icon-textgray">{{'dashboard.onlineCams' | translate}}</div>
            </mat-card-content>
        </mat-card>
        <mat-card class="card top">
            <mat-card-content>
                <div class="title-text-card icon-secondary" [ngClass]="warningColor()">{{'dashboard.camSettings' | translate}}</div>
                <div class="header-number-card icon-blue" [ngClass]="warningColor()">{{unfinishedCameras}}</div>
                <div class="header-text-card icon-textgray" [ngClass]="warningColor()">{{'dashboard.warning_2_desc' | translate}} </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="card-container">
        <mat-card class="card">
            <mat-card-header class="flex-column-baseline">
                <div class="flex title-text-card icon-primary">{{'dashboard.watchzone' | translate}}</div>
            </mat-card-header>
            <ul class="outer">
                <li class="inner" *ngFor="let elem of groupStatus">
                    <map-card [data]="elem"></map-card>
                </li>
            </ul>
        </mat-card>
    </div>
</div>