import { Component } from '@angular/core';
import { dashboardService } from '../../services/dashboard.service';
import { statusData } from '../../models/statusData';
import { groupData } from '../../models/groupData';
import { trending } from '../../../../@shared/models/trend-enum';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})

export class DashboardComponent {
  status: statusData = new statusData;
  groupStatus: groupData[];
  refreshRate = 60;
  unfinishedCameras = 0;

  constructor(private dashboardService: dashboardService) {
    this.getStatusData();
    this.getGroupsData();
    this.getUnfinishedCameras();
  }

  getStatusData() {
    this.dashboardService
      .getStatusData()
      .subscribe({
        next: (data: statusData) => {
          if (data){
            this.status = data;
          }
        },
        error: () => {
          console.error("something went wrong.");
        },
      });
  }

  getGroupsData() {
    this.dashboardService
      .getGroupsData()
      .subscribe({
        next: (data: groupData[]) => {
          if (data){
            this.groupStatus = data;
            let _this = this;
            _this.groupStatus = Object.keys(_this.groupStatus ).map(function(key) {return _this.groupStatus [key];});
          }
        },
        error: () => {
          console.error("something went wrong.");
        },
      });
    }

  getUnfinishedCameras() {
    this.dashboardService
      .getUnfinishedCameras()
      .subscribe({
        next: (data: groupData[]) => {
          if (data){
            let _this = this;
            let tmp = Object.keys(data).map(function(key) {return data [key];});
            this.unfinishedCameras = tmp.length;
          }
        },
        error: () => {
          console.error("something went wrong.");
        },
      });
  }

  getIcon() {
    return trending.getIcon(this.status.trend);
  }

  getColor() {
    return trending.getColor(this.status.trend);
  }

  warningColor() {
    if (this.unfinishedCameras > 0) {
      return 'error-color';
    }
  }
}

