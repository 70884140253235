import { HttpService } from '../../../@core/services/http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { camera } from '../../../@shared/models/camera';

@Injectable({
    providedIn: 'root',
  })
export class cameraSettingsService {

    constructor(
      private api: HttpService
    ) {}

    getCameras(search?: string): Observable<any> {

    let query = 'cameras?page=1&limit=100';
    if (search) {
        query = `cameras?page=1&limit=100&search=${search}`
    }
    return this.api.get(query).pipe(
        map((result) => {
            return { ...result };
            })
        );
    }

    getCamerasPending(search?: string): Observable<any> {

        let query = 'cameras/setup/pending';

        if (search) {
            query = `cameras/setup/pending?search=${search}`
        }

        return this.api.get(query).pipe(
            map((result) => {
                return { ...result };
                })
            );
        }

    createNewCamera(data): Observable<any> {
        return this.api.post('cameras', data).pipe(
            map((result) => {
                return {...result};
            })
        );
    }

    updateCamera(data, idCamera: string): Observable<any> {
        return this.api.patch(`cameras/${idCamera}`, data).pipe(
            map((result) => {
                return {...result};
            })
        );
    }

    createBodyFromCamera(cam: camera) {
        return (
            {
              "code": cam.code,
              "description": cam.description,
              "watch_zone": cam.watch_zone,
              "location": {
                "latitude": cam.location.latitude,
                "longitude": cam.location.longitude
              },
              "thresholds": {
                "warning_level": cam.thresholds.warning_level,
                "critical_level": cam.thresholds.critical_level
              },
              "is_active": cam.is_active
            }
          )
    }
}