import { Role } from './role.enum';
import { AppRoutes } from './routes.enum';

export const RoutesByRole = [
  {
    role: Role.Administrator,
    routes: [
      AppRoutes.AdminSettings,
      AppRoutes.ChangePwd
    ],
  },
  {
    role: Role.Account_manager,
    routes: [
      AppRoutes.CameraSettings,
      AppRoutes.Cameras,
      AppRoutes.Dashboard,
      AppRoutes.Heatmap,
      AppRoutes.Statistics,
      AppRoutes.UserSettings,
      AppRoutes.ApiSettings,
      AppRoutes.Settings,
      AppRoutes.ChangePwd,
      AppRoutes.Thresholds
    ]
  },
  {
    role: Role.Operator,
    routes: [
      AppRoutes.Cameras,
      AppRoutes.Dashboard,
      AppRoutes.Heatmap,
      AppRoutes.Statistics,
      AppRoutes.ChangePwd,
      AppRoutes.Thresholds
    ]
  },
];
