import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HertaBrandComponent } from './ui/herta-brand/herta-brand.component';
import { HertaLoadingComponent } from './ui/herta-loading/herta-loading.component';
import { HertaFaceRecognitionComponent } from './ui/herta-face-recognition/herta-face-recognition.component';
import { ToastrModule } from 'ngx-toastr';
import { CheckedPipe } from './pipes/checked.pipe';
import { YesNoPipe } from './pipes/yes-no.pipe';
import { DateValueAccessorDirective } from './directives/date-value-accessor.directive';
import { DatetimeLocalValueAccessorDirective } from './directives/datetime-local-value-accessor.directive';
import { MatNativeDateModule } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { Base64ImagePipe } from './pipes/base64-image.pipe';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginationIntlService } from './services/mat-pagination-intl.service';
import { SpinnerComponent } from './modules/spinner/spinner.component';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { DeleteTemplateComponent } from './components/delete-dialog/delete-template.component';
import { CountifyBrandComponent } from './ui/countify-brand/countify-brand.component';
import { HertaverseBrandComponent } from './ui/hertaverse-brand/hertaverse-brand.component';
import { PinBrandComponent } from './ui/pin-brand/pin-brand.component';
import { CountifyNavComponent } from './ui/countify-nav/countify-nav.component';

const pipes = [CheckedPipe, Base64ImagePipe, YesNoPipe];

const directives = [DateValueAccessorDirective, DatetimeLocalValueAccessorDirective];

@NgModule({
  declarations: [
    HertaBrandComponent,
    HertaLoadingComponent,
    HertaFaceRecognitionComponent,
    DeleteTemplateComponent,
    SpinnerComponent,
    CountifyBrandComponent,
    CountifyNavComponent,
    HertaverseBrandComponent,
    PinBrandComponent,
    ...pipes,
    ...directives,
  ],
  exports: [
    HertaBrandComponent,
    HertaLoadingComponent,
    HertaFaceRecognitionComponent,
    CountifyNavComponent,
    CommonModule,
    ReactiveFormsModule,
    SpinnerComponent,
    TranslateModule,
    CountifyBrandComponent,
    HertaverseBrandComponent,
    PinBrandComponent,
    ...pipes,
    ...directives,
    DeleteTemplateComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    MatNativeDateModule,
    ToastrModule.forRoot(),
    MatDialogModule,
    MatButtonModule,
    MatCardModule,
    MatProgressSpinner,
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginationIntlService,
    },
    ...pipes,
    ...directives,
  ],
})
export class SharedModule {}
