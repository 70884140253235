export enum AppRoutes {
  CameraSettings = 'cameraSettings',
  Modules = 'modules',
  Cameras = 'cameras',
  Dashboard = 'dashboard',
  Heatmap = 'heatmap',
  Statistics = 'statistics',
  UserSettings = 'userSettings',
  ApiSettings = 'apiSettings',
  AdminSettings = 'adminSettings',
  Settings = "Settings",
  ChangePwd = "password",
  Thresholds = "thresholds"
}
