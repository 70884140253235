<div class="container">
    <div class="title icon-blue">
        {{data.name}}
    </div>
    <div class="flex-row separated">
        <div class="flex-row">

            <div [ngClass]="getColor()" class="number-card">
                {{data ? data.totalPeopleCount : 'null'}}
            </div>
            <mat-icon [ngClass]="getColor()">{{icon_pic}}</mat-icon>
        </div>
        <div class="flex-row">
            <div class="number-card icon-primary">
                {{data ? data.totalCameraCount : 'null'}}
            </div>
            <mat-icon class="icon-primary">videocam</mat-icon>
        </div>
    </div>
    <div (click)="moveToCameraPage()">
        <google-map width="250px" height="250px" [center]="center" [options]="optionsGmaps">
            <map-circle [center]="center" [radius]="circle.radius" [options]="circle.options" />
        </google-map>
    </div>
</div>