import { Component, Input} from '@angular/core';
import { groupData } from '../../models/groupData';
import { trending } from '../../../../@shared/models/trend-enum';
import { Router } from '@angular/router';


@Component({
  selector: 'map-card',
  templateUrl: './map-card.component.html',
  styleUrl: './map-card.component.scss'
})
export class MapCardComponent {

  @Input() data: groupData;

  icon_pic: string;

  optionsGmaps: google.maps.MapOptions = {
    disableDefaultUI: true,
    zoom: 15.5,
    disableDoubleClickZoom: true,
    keyboardShortcuts: false,
    gestureHandling: "none",
    clickableIcons: false,
    draggableCursor: "pointer"
  };
  center: google.maps.LatLngLiteral;

  circle = {
    radius: 200,
    options: {
      draggable: false,
      clickable: false,
      fillOpacity: 0.3,
      fillColor: "#275C9A",
      strokeOpacity: 0
      
    }

  }

  constructor(private router: Router) {
  }

  ngOnInit() {
    this.icon_pic = this.getIcon();
    this.center = {
      lat: this.data.location.latitude,
      lng: this.data.location.longitude,
    }
  }

  getColor() {
    return trending.getColor(this.data.trend);
  }

  getIcon() {
    return trending.getIcon(this.data.trend);
  }

  moveToCameraPage() {
    const url = `/modules/cameras?watchzone=${this.data.name}`;
    this.router.navigateByUrl(url);
  }

}
