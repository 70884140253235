import { trend } from "./trend-enum";

export class camera {
    id: string = "";
    account_id: string = "";
    code: string = "";
    description: string = "";
    watch_zone: string = "";
    totalPeopleCount: number = 0;
    trend: trend = trend.steady;
    location = {
      latitude: 0,
      longitude: 0
    };
    thresholds = {
      warning_level: 0,
      critical_level: 0
    };
    is_active: boolean = true;
    created_at: string = "";
    updated_at: string = "";
    isCompleted: boolean = true;
    date: string = "";
    image_url: string = "";
}