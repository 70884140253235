export enum trend {
    increasing = "increasing", 
    decreasing = "decreasing",
    steady = "steady"
  }

  export class trending {
    
    static getIcon(trending: trend) {
      switch (trending) {
        case trend.increasing:
          return "trending_up";
        case trend.decreasing:
          return "trending_down";
        case trend.steady:
          return "trending_flat"
        default:
          return "";
      }
    }
  
   static getColor(trending: trend) {
      switch (trending) {
        case trend.increasing:
          return "icon-primary";
        case trend.decreasing:
          return "icon-secondary";
        case trend.steady:
          return "icon-tertiary"
        default:
          return "";
      }
    }
  }
