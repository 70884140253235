<svg
  width="195"
  height="180"
  viewBox="0 0 200 200"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <g id="herta_tag">
    <g id="h">
      <path
        id="Vector"
        d="M147.945 163H112.589V103.797C112.589 92.4437 111.417 85.5947 109.072 83.2501C107.743 82.0168 106.177 81.0665 104.469 80.4566C102.762 79.8468 100.948 79.5901 99.1383 79.7022C93.0223 79.9292 87.1357 82.0894 82.3245 85.8724V163H47V18H82.3245V57.9213C89.9052 52.8413 98.8365 50.1544 107.962 50.2086C120.014 50.2086 129.434 53.5919 136.221 60.3586C141.692 65.9935 145.319 73.16 146.618 80.9054C147.695 88.2167 148.138 95.6072 147.945 102.995V163Z"
        fill="#00205C" />
    </g>
    <g id="corchetes">
      <path
        id="Vector_2"
        d="M66 69.9711V77.9422H73.9711V69.9711H81.9422V62H73.9711H66V69.9711Z"
        fill="#B1B1B1" />
      <path
        id="Vector_3"
        d="M66 110.255V102.284H73.9711V110.255H81.9422V118.219H73.9711H66V110.255Z"
        fill="#B1B1B1" />
      <path
        id="Vector_4"
        d="M127.702 69.9711V77.9422H119.731V69.9711H111.76V62H119.731H127.702V69.9711Z"
        fill="#B1B1B1" />
      <path
        id="Vector_5"
        d="M127.702 110.255V102.284H119.731V110.255H111.76V118.219H119.731H127.702V110.255Z"
        fill="#B1B1B1" />
    </g>
  </g>
</svg>
