<svg
  width="216"
  height="216"
  viewBox="0 0 216 216"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <g id="face_recognition">
    <g id="face">
      <path
        id="Vector"
        d="M107.307 30.0071C107.307 30.0071 81.9563 29.2251 71.2815 44.1091C60.6068 58.9932 60.0289 68.9813 59.4567 74.8556C58.8845 80.7299 57.3587 106.388 57.9309 112.068C58.5031 117.747 63.2712 149.474 64.4155 152.411C65.5598 155.348 82.1414 172.975 85.7632 176.696C89.385 180.417 97.3973 185.117 102.919 185.704C108.44 186.291 106.543 185.704 106.543 185.704"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-1"></path>
      <path
        id="Vector_2"
        d="M107.307 30.0068L87.1573 62.9981L81.1934 42.1502L86.7053 33.3369L107.307 46.8496V81.1226L94.2503 88.1736L87.1573 62.9981L107.307 81.1226"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-2"></path>
      <path
        id="Vector_3"
        d="M73.0056 41.9731L81.1934 42.1505"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-3"></path>
      <path
        id="Vector_4"
        d="M87.1573 62.9983L66.6755 51.2612"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-4"></path>
      <path
        id="Vector_5"
        d="M73.0057 41.9731L70.9001 54.0955"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-5"></path>
      <path
        id="Vector_6"
        d="M70.8525 100.708L78.0485 94.2446"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-6"></path>
      <path
        id="Vector_7"
        d="M70.8525 100.708L80.1446 105.554"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-7"></path>
      <path
        id="Vector_8"
        d="M80.1445 105.555L96.0127 103.865"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-8"></path>
      <path
        id="Vector_9"
        d="M78.0483 94.2446L93.0106 96.0069"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-9"></path>
      <path
        id="Vector_10"
        d="M96.0125 103.865L93.0105 96.0068"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-10"></path>
      <path
        id="Vector_11"
        d="M70.9001 54.0952L68.5657 71.0372"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-11"></path>
      <path
        id="Vector_12"
        d="M59.3574 76.0379L68.5655 71.0371"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-12"></path>
      <path
        id="Vector_13"
        d="M78.0484 94.2443L68.5657 71.0371"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-13"></path>
      <path
        id="Vector_14"
        d="M87.1573 62.9985L78.0483 94.2447"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-14"></path>
      <path
        id="Vector_15"
        d="M94.2503 88.1738L78.0483 94.2446"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-15"></path>
      <path
        id="Vector_16"
        d="M93.0105 96.0068L94.2502 88.1738"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-16"></path>
      <path
        id="Vector_17"
        d="M68.5657 71.0375L87.1574 62.9985"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-17"></path>
      <path
        id="Vector_18"
        d="M107.307 46.8496L87.1572 62.9981"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-18"></path>
      <path
        id="Vector_19"
        d="M68.5657 71.0371L70.8524 100.708"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-19"></path>
      <path
        id="Vector_20"
        d="M58.4897 89.3086L70.8524 100.708"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-20"></path>
      <path
        id="Vector_21"
        d="M70.8523 100.708L58.625 116.475"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-21"></path>
      <path
        id="Vector_22"
        d="M80.1444 105.554L58.6079 116.58"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-22"></path>
      <path
        id="Vector_23"
        d="M58.4934 116.498L80.8118 132.288"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-23"></path>
      <path
        id="Vector_24"
        d="M80.1445 105.554L80.8121 132.288"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-24"></path>
      <path
        id="Vector_25"
        d="M96.0127 103.865L101.303 110.107"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-25"></path>
      <path
        id="Vector_26"
        d="M97.0864 132.288L101.303 110.107"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-26"></path>
      <path
        id="Vector_27"
        d="M107.307 138.456L97.0864 132.288"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-27"></path>
      <path
        id="Vector_28"
        d="M97.0864 132.288L107.307 122.887"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-28"></path>
      <path
        id="Vector_29"
        d="M101.303 110.107L107.307 122.887"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-29"></path>
      <path
        id="Vector_30"
        d="M94.2502 88.1738L108.165 104.637"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-30"></path>
      <path
        id="Vector_31"
        d="M101.303 110.107L108.165 104.637"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-31"></path>
      <path
        id="Vector_32"
        d="M94.2502 88.1738L101.303 110.107"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-32"></path>
      <path
        id="Vector_33"
        d="M97.0864 132.288L107.004 132.582"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-33"></path>
      <path
        id="Vector_34"
        d="M87.865 153.44L102.232 144.92"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-34"></path>
      <path
        id="Vector_35"
        d="M107.307 147.857L102.232 144.92"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-35"></path>
      <path
        id="Vector_36"
        d="M87.865 153.439L96.2988 157.845"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-36"></path>
      <path
        id="Vector_37"
        d="M107.307 158.139L96.2988 157.845"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-37"></path>
      <path
        id="Vector_38"
        d="M100.203 185.155L107.307 165.19"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-38"></path>
      <path
        id="Vector_39"
        d="M96.2988 157.845L107.307 165.19"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-39"></path>
      <path
        id="Vector_40"
        d="M64.4194 152.411L80.1446 105.554"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-40"></path>
      <path
        id="Vector_41"
        d="M80.8121 132.288L64.4194 152.411"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-41"></path>
      <path
        id="Vector_42"
        d="M87.865 153.439L64.4194 152.411"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-42"></path>
      <path
        id="Vector_43"
        d="M84.6511 175.537L87.8648 153.439"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-43"></path>
      <path
        id="Vector_44"
        d="M96.2986 157.845L84.6511 175.537"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-44"></path>
      <path
        id="Vector_45"
        d="M100.203 185.155L96.2988 157.845"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-45"></path>
      <path
        id="Vector_46"
        d="M102.232 144.92L80.812 132.288"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-46"></path>
      <path
        id="Vector_47"
        d="M87.825 153.706L97.0865 132.288"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-47"></path>
      <path
        id="Vector_48"
        d="M80.1445 105.554L97.0865 132.288"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-48"></path>
      <path
        id="Vector_49"
        d="M80.812 132.288H97.0864"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-49"></path>
      <path
        id="Vector_50"
        d="M80.812 132.288L87.865 153.439"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-50"></path>
      <path
        id="Vector_51"
        d="M58.2742 93.4987C58.2742 93.4987 50.8359 87.777 50.1226 95.1255C49.4093 102.474 51.9822 109.081 53.3402 115.983C54.6981 122.885 55.1272 131.554 56.5557 133.022C57.9842 134.491 61.1789 134.638 61.1789 134.638"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-51"></path>
      <path
        id="Vector_52"
        d="M52.1807 91.5459L58.4936 116.498"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-52"></path>
      <path
        id="Vector_53"
        d="M55.5889 130.47L58.6252 116.475"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-53"></path>
      <path
        id="Vector_54"
        d="M107.412 30.0071C107.412 30.0071 132.765 29.2251 143.438 44.1091C154.111 58.9932 154.691 68.9813 155.263 74.8556C155.835 80.7299 157.361 106.388 156.788 112.068C156.216 117.747 151.448 149.474 150.304 152.411C149.16 155.348 132.578 172.975 128.954 176.696C125.331 180.417 117.32 185.117 111.799 185.704C106.277 186.291 108.175 185.704 108.175 185.704"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-54"></path>
      <path
        id="Vector_55"
        d="M107.412 30.0068L127.562 62.9981L133.526 42.1502L128.014 33.3369L107.412 46.8496V81.1226L120.469 88.1736L127.562 62.9981L107.412 81.1226"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-55"></path>
      <path
        id="Vector_56"
        d="M141.714 41.9731L133.526 42.1505"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-56"></path>
      <path
        id="Vector_57"
        d="M127.562 62.9983L148.044 51.2612"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-57"></path>
      <path
        id="Vector_58"
        d="M141.714 41.9731L143.819 54.0955"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-58"></path>
      <path
        id="Vector_59"
        d="M143.867 100.708L136.671 94.2446"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-59"></path>
      <path
        id="Vector_60"
        d="M143.867 100.708L134.575 105.554"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-60"></path>
      <path
        id="Vector_61"
        d="M134.575 105.555L118.707 103.865"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-61"></path>
      <path
        id="Vector_62"
        d="M136.671 94.2446L121.709 96.0069"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-62"></path>
      <path
        id="Vector_63"
        d="M118.707 103.865L121.709 96.0068"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-63"></path>
      <path
        id="Vector_64"
        d="M143.819 54.0952L146.156 71.0372"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-64"></path>
      <path
        id="Vector_65"
        d="M155.362 76.0379L146.156 71.0371"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-65"></path>
      <path
        id="Vector_66"
        d="M136.671 94.2443L146.156 71.0371"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-66"></path>
      <path
        id="Vector_67"
        d="M127.562 62.9985L136.671 94.2447"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-67"></path>
      <path
        id="Vector_68"
        d="M120.469 88.1738L136.671 94.2446"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-68"></path>
      <path
        id="Vector_69"
        d="M121.709 96.0068L120.469 88.1738"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-69"></path>
      <path
        id="Vector_70"
        d="M146.156 71.0375L127.562 62.9985"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-70"></path>
      <path
        id="Vector_71"
        d="M107.412 46.8496L127.562 62.9981"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-71"></path>
      <path
        id="Vector_72"
        d="M146.156 71.0371L143.867 100.708"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-72"></path>
      <path
        id="Vector_73"
        d="M156.23 89.3086L143.867 100.708"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-73"></path>
      <path
        id="Vector_74"
        d="M143.867 100.708L156.094 116.475"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-74"></path>
      <path
        id="Vector_75"
        d="M134.575 105.554L156.111 116.58"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-75"></path>
      <path
        id="Vector_76"
        d="M156.226 116.498L133.907 132.288"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-76"></path>
      <path
        id="Vector_77"
        d="M134.575 105.554L133.907 132.288"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-77"></path>
      <path
        id="Vector_78"
        d="M118.707 103.865L113.416 110.107"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-78"></path>
      <path
        id="Vector_79"
        d="M117.635 132.288L113.416 110.107"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-79"></path>
      <path
        id="Vector_80"
        d="M107.412 138.456L117.635 132.288"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-80"></path>
      <path
        id="Vector_81"
        d="M117.635 132.288L107.412 122.887"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-81"></path>
      <path
        id="Vector_82"
        d="M113.416 110.107L107.412 122.887"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-82"></path>
      <path
        id="Vector_83"
        d="M120.469 88.1738L106.556 104.637"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-83"></path>
      <path
        id="Vector_84"
        d="M113.416 110.107L106.556 104.637"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-84"></path>
      <path
        id="Vector_85"
        d="M120.469 88.1738L113.416 110.107"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-85"></path>
      <path
        id="Vector_86"
        d="M117.635 132.288L107.004 132.582"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-86"></path>
      <path
        id="Vector_87"
        d="M126.854 153.44L112.487 144.92"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-87"></path>
      <path
        id="Vector_88"
        d="M107.412 147.857L112.487 144.92"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-88"></path>
      <path
        id="Vector_89"
        d="M126.854 153.439L118.421 157.845"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-89"></path>
      <path
        id="Vector_90"
        d="M107.412 158.139L118.421 157.845"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-90"></path>
      <path
        id="Vector_91"
        d="M114.517 185.155L107.412 165.19"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-91"></path>
      <path
        id="Vector_92"
        d="M118.421 157.845L107.412 165.19"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-92"></path>
      <path
        id="Vector_93"
        d="M150.3 152.411L134.575 105.554"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-93"></path>
      <path
        id="Vector_94"
        d="M133.907 132.288L150.3 152.411"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-94"></path>
      <path
        id="Vector_95"
        d="M126.854 153.439L150.3 152.411"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-95"></path>
      <path
        id="Vector_96"
        d="M130.068 175.537L126.854 153.439"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-96"></path>
      <path
        id="Vector_97"
        d="M118.421 157.845L130.068 175.537"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-97"></path>
      <path
        id="Vector_98"
        d="M114.517 185.155L118.421 157.845"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-98"></path>
      <path
        id="Vector_99"
        d="M112.487 144.92L133.907 132.288"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-99"></path>
      <path
        id="Vector_100"
        d="M126.895 153.706L117.635 132.288"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-100"></path>
      <path
        id="Vector_101"
        d="M134.575 105.554L117.635 132.288"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-101"></path>
      <path
        id="Vector_102"
        d="M133.908 132.288H117.635"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-102"></path>
      <path
        id="Vector_103"
        d="M133.907 132.288L126.854 153.439"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-103"></path>
      <path
        id="Vector_104"
        d="M156.445 93.4987C156.445 93.4987 163.883 87.777 164.597 95.1255C165.31 102.474 162.737 109.081 161.379 115.983C160.021 122.885 159.592 131.554 158.164 133.022C156.735 134.491 153.541 134.638 153.541 134.638"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-104"></path>
      <path
        id="Vector_105"
        d="M162.539 91.5459L156.226 116.498"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-105"></path>
      <path
        id="Vector_106"
        d="M159.131 130.47L156.094 116.475"
        stroke="#DAE1FF"
        stroke-width="0.5"
        stroke-miterlimit="10"
        class="svg-106"></path>
    </g>
    <g id="puntos_03">
      <circle
        id="Ellipse 6"
        cx="127.5"
        cy="63.5"
        r="1"
        fill="#DAE1FF"
        class="svg-107"></circle>
      <circle
        id="Ellipse 7"
        cx="87.5"
        cy="63.5"
        r="1"
        fill="#DAE1FF"
        class="svg-108"></circle>
      <circle
        id="Ellipse 15"
        cx="107.5"
        cy="104.5"
        r="1"
        fill="#DAE1FF"
        class="svg-109"></circle>
      <circle
        id="Ellipse 10"
        cx="126.5"
        cy="153.5"
        r="1"
        fill="#DAE1FF"
        class="svg-110"></circle>
      <circle
        id="Ellipse 13"
        cx="133.5"
        cy="132.5"
        r="1"
        fill="#DAE1FF"
        class="svg-111"></circle>
      <circle
        id="Ellipse 14"
        cx="81.5"
        cy="132.5"
        r="1"
        fill="#DAE1FF"
        class="svg-112"></circle>
      <circle
        id="Ellipse 11"
        cx="88.5"
        cy="153.5"
        r="1"
        fill="#DAE1FF"
        class="svg-113"></circle>
    </g>
    <g id="puntos_05">
      <circle
        id="Ellipse 8"
        cx="94.5"
        cy="88.5"
        r="2"
        fill="#DAE1FF"
        class="svg-114"></circle>
      <circle
        id="Ellipse 9"
        cx="120.5"
        cy="88.5"
        r="2"
        fill="#DAE1FF"
        class="svg-115"></circle>
      <circle
        id="Ellipse 19"
        cx="107.5"
        cy="81.5"
        r="2"
        fill="#DAE1FF"
        class="svg-116"></circle>
      <circle
        id="Ellipse 20"
        cx="81.5"
        cy="42.5"
        r="2"
        fill="#DAE1FF"
        class="svg-117"></circle>
      <circle
        id="Ellipse 21"
        cx="133.5"
        cy="42.5"
        r="2"
        fill="#DAE1FF"
        class="svg-118"></circle>
      <circle
        id="Ellipse 12"
        cx="107.5"
        cy="147.5"
        r="2"
        fill="#DAE1FF"
        class="svg-119"></circle>
      <circle
        id="Ellipse 16"
        cx="107.5"
        cy="185.5"
        r="2"
        fill="#DAE1FF"
        class="svg-120"></circle>
    </g>
    <g id="puntos_10">
      <circle
        id="Ellipse 1"
        cx="71.5"
        cy="100.5"
        r="3"
        fill="#DAE1FF"
        class="svg-121"></circle>
      <circle
        id="Ellipse 17"
        cx="64.5"
        cy="152.5"
        r="3"
        fill="#DAE1FF"
        class="svg-122"></circle>
      <circle
        id="Ellipse 18"
        cx="150.5"
        cy="152.5"
        r="3"
        fill="#DAE1FF"
        class="svg-123"></circle>
      <circle
        id="Ellipse 2"
        cx="144.5"
        cy="100.5"
        r="3"
        fill="#DAE1FF"
        class="svg-124"></circle>
      <circle
        id="Ellipse 5"
        cx="107.5"
        cy="123.5"
        r="3"
        fill="#DAE1FF"
        class="svg-125"></circle>
      <circle
        id="Ellipse 3"
        cx="107.5"
        cy="164.5"
        r="3"
        fill="#DAE1FF"
        class="svg-126"></circle>
      <circle
        id="Ellipse 4"
        cx="107.5"
        cy="47.5"
        r="3"
        fill="#DAE1FF"
        class="svg-127"></circle>
    </g>
    <g id="corch">
      <path
        id="Vector_107"
        d="M0 44.393V38.8672H5.52592V44.393H11.0523V49.9189H5.52592H0V44.393Z"
        fill="#B1B1B1"></path>
      <path
        id="Vector_108"
        d="M0 5.52098V11.0473H5.52592V5.52098H11.0523V-0.00488281H5.52592H0V5.52098Z"
        fill="#B1B1B1"></path>
      <path
        id="Vector_109"
        d="M50 44.393V38.8672H44.4741V44.393H38.9478V49.9189H44.4741H50V44.393Z"
        fill="#B1B1B1"></path>
      <path
        id="Vector_110"
        d="M50 5.52098V11.0473H44.4741V5.52098H38.9478V-0.00488281H44.4741H50V5.52098Z"
        fill="#B1B1B1"></path>
    </g>
  </g>
</svg>
