<svg width="32" height="28" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="pin_map" clip-path="url(#clip0_734_136)">
    <g id="blue">
    <path id="Vector" d="M3.86464 17.4726L0 13.531L3.86464 9.58936L10.699 13.531L3.86464 17.4726ZM20.5843 13.531L27.4186 17.4726L31.2223 13.531L27.378 9.58936L20.5843 13.531Z" fill="#009CEA" class="svg-elem-1"></path>
    </g>
    <g id="green">
    <path id="Vector_2" d="M6.32617 5.30292L7.83135 0L13.1605 1.36129V9.24454L6.32617 5.30292ZM18.1032 17.7983V25.6815L23.4527 27.0428L24.9375 21.7399L18.1032 17.7983Z" fill="#275C9A" class="svg-elem-2"></path>
    </g>
    <g id="dark_blue">
    <path id="Vector_3" d="M13.1605 25.6815L7.83135 27.0428L6.32617 21.7399L13.1605 17.7983V25.6815ZM18.1032 9.24454L24.9375 5.30292L23.4527 0L18.1032 1.36129V9.24454Z" fill="#02BEBE" class="svg-elem-3"></path>
    </g>
    </g>
    <defs>
    <clipPath id="clip0_734_136">
    <rect width="32" height="28" fill="white" class="svg-elem-4"></rect>
    </clipPath>
    </defs>
    </svg>
    