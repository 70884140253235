<div class="row">
  <div class="col-md-12">
    <mat-card>
      <mat-card-content>
        <div class="flex-centered col-xl-4 col-lg-6 col-md-8 col-sm-12">
          <h2 class="title">404 Page Not Found</h2>
          <small class="sub-title">The page you were looking for doesn't exist</small>
          <button
            mat-raised-button
            (click)="goToHome()"
            type="button"
            class="home-button">
            Take me home
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
