import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'hrt-delete-template',
  templateUrl: './delete-template.component.html',
  styleUrl: './delete-template.component.scss',
})
export class DeleteTemplateComponent {
  @Input({ required: true }) title: string;
  @Output() confirm: EventEmitter<void> = new EventEmitter();
  @Output() cancel: EventEmitter<void> = new EventEmitter();

  onCancel() {
    this.cancel.emit();
  }

  onConfirm() {
    this.confirm.emit();
  }
}
