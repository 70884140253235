<svg
  class="logo-hrz-animation"
  width="209"
  height="91"
  viewBox="0 0 209 91"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <rect
    width="209"
    height="91"
    fill="none"
    class="svg-1"></rect>
  <g
    id="herta_mainbrand"
    clip-path="url(#clip0_11_1833)">
    <g id="h">
      <path
        id="Vector_5"
        d="M54.3299 68.5701H42.8699V49.3801C42.8699 45.7134 42.4899 43.4934 41.7299 42.7201C41.2969 42.3241 40.7887 42.0191 40.2356 41.8232C39.6824 41.6274 39.0956 41.5447 38.5099 41.5801C36.5282 41.6577 34.6214 42.3575 33.0599 43.5801V68.5801H21.6099V21.5801H33.0599V34.4701C35.5162 32.8205 38.4111 31.9461 41.3699 31.9601C45.2765 31.9601 48.3332 33.0601 50.5399 35.2601C52.3174 37.0822 53.4906 39.4076 53.8999 41.9201C54.2492 44.2899 54.393 46.6855 54.3299 49.0801V68.5701Z"
        fill="#00205C"
        class="svg-2"></path>
    </g>
    <g id="e">
      <path
        id="Vector_4"
        d="M92.4598 50.63C92.4598 51.2 92.4598 51.83 92.3898 52.53C92.3198 53.23 92.3098 53.68 92.3098 53.92H68.8698C68.8843 54.7792 69.0685 55.627 69.4117 56.4147C69.7549 57.2024 70.2505 57.9145 70.8698 58.51C71.8005 59.4231 72.9124 60.1307 74.1337 60.5872C75.355 61.0437 76.6584 61.2388 77.9598 61.16C81.1586 61.1203 84.293 60.2557 87.0598 58.65L90.4198 65.38C89.1659 66.3229 87.7633 67.0496 86.2698 67.53C83.3263 68.6906 80.1938 69.2975 77.0298 69.32C70.8698 69.32 66.1432 67.34 62.8499 63.38C59.967 59.7968 58.4691 55.2962 58.6298 50.7C58.4401 45.7036 60.1681 40.8237 63.4598 37.06C65.0354 35.2902 66.9807 33.8882 69.158 32.9533C71.3353 32.0184 73.6915 31.5735 76.0598 31.65C81.3132 31.65 85.3598 33.3567 88.1998 36.77C91.0398 40.1834 92.4598 44.8034 92.4598 50.63ZM82.4598 46.33C82.4745 44.9521 82.1298 43.5942 81.4598 42.39C80.9777 41.4529 80.2329 40.6766 79.3164 40.1562C78.3999 39.6358 77.3517 39.3939 76.2998 39.4601C75.1468 39.4133 74.0022 39.6739 72.983 40.2151C71.9639 40.7563 71.1069 41.5587 70.4999 42.54C69.7099 43.6619 69.2178 44.966 69.0698 46.33H82.4598Z"
        fill="#00205C"
        class="svg-3"></path>
    </g>
    <g id="r">
      <path
        id="Vector_3"
        d="M120.13 32.6698C119.638 32.5786 119.14 32.5318 118.64 32.5298C115.58 32.5298 112.72 32.9098 110.04 34.7698C108.759 35.5467 107.641 36.5657 106.75 37.7698V33.0498H97.2998V68.5598H108.75V46.2498C110.85 43.0098 114.03 41.5498 118.28 41.5498C118.903 41.541 119.526 41.6149 120.13 41.7698V32.6698Z"
        fill="#00205C"
        class="svg-4"></path>
    </g>
    <g id="t">
      <path
        id="Vector_2"
        d="M152.79 66.8099C151.941 67.4138 150.999 67.8733 150 68.1699C147.789 68.9404 145.462 69.3293 143.12 69.3199C138.874 69.3199 135.604 68.1732 133.31 65.8799C131.853 64.4457 130.877 62.5934 130.52 60.5799C130.16 58.3086 130.016 56.0084 130.09 53.7099V41.7099H123.93V32.7099H130.09V22.8999H141.55V32.7099H151.21V41.7099H141.55V54.5299C141.482 55.7959 141.676 57.0624 142.12 58.2499C142.464 58.8811 142.988 59.3953 143.626 59.7267C144.264 60.058 144.986 60.1915 145.7 60.1099C147.148 60.1276 148.586 59.8592 149.93 59.3199L152.79 66.8099Z"
        fill="#00205C"
        class="svg-5"></path>
    </g>
    <g id="a">
      <path
        id="Vector"
        d="M186.59 68.25H177.14V65.25C174.358 67.6528 170.816 68.9952 167.14 69.04C165.591 69.1182 164.043 68.8721 162.595 68.3177C161.147 67.7632 159.83 66.9125 158.73 65.82C157.679 64.701 156.861 63.3834 156.325 61.9445C155.79 60.5055 155.546 58.9741 155.61 57.44C155.61 48.7 162.127 44.3333 175.16 44.34H175.95C176.079 43.1967 175.772 42.0468 175.09 41.12C174.33 40.35 172.92 39.97 170.87 39.97C167.428 39.9931 164.04 40.8255 160.98 42.4L157.55 35.6C160.983 33.02 165.613 31.73 171.44 31.73C176.88 31.73 180.987 33.1167 183.76 35.89C185.15 37.3441 186.049 39.1979 186.33 41.19C186.576 43.2805 186.673 45.3858 186.62 47.49L186.59 68.25ZM176.06 58.87V51.64C170.813 51.64 167.543 52.2867 166.25 53.58C165.854 53.9708 165.542 54.4386 165.334 54.9546C165.126 55.4706 165.026 56.0238 165.04 56.58C165.04 59.45 166.51 60.88 169.48 60.88C171.817 60.8201 174.092 60.1215 176.06 58.86V58.87Z"
        fill="#00205C"
        class="svg-6"></path>
    </g>
    <g id="corch-left">
      <path
        id="Vector_6"
        d="M0 10.8V21.6H10.8V10.8H21.6V0H10.8H0V10.8Z"
        fill="#B1B1B1" />
      <path
        id="Vector_7"
        d="M0 79.3801V68.5801H10.8V79.3801H21.6V90.1701H10.8H0V79.3801Z"
        fill="#B1B1B1" />
    </g>
    <g id="corch-right">
      <path
        id="Vector_8"
        d="M208.19 10.8V21.6H197.39V10.8H186.59V0H197.39H208.19V10.8Z"
        fill="#B1B1B1" />
      <path
        id="Vector_9"
        d="M208.19 79.3801V68.5801H197.39V79.3801H186.59V90.1701H197.39H208.19V79.3801Z"
        fill="#B1B1B1" />
    </g>
  </g>
  <defs>
    <clipPath id="clip0_11_1833">
      <rect
        width="208.19"
        height="90.18"
        fill="white"
        class="svg-7"></rect>
    </clipPath>
  </defs>
</svg>
